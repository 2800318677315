<script setup>
import Modal from './Modal.vue'

const emit = defineEmits(['close'])

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    maxWidth: {
        type: String,
        default: '2xl',
    },
    closeable: {
        type: Boolean,
        default: true,
    },
    overflowVisible: {
        type: Boolean,
        default: false,
    },
})

const close = () => {
    emit('close')
}
</script>

<template>
    <Modal :show="show" :max-width="maxWidth" :closeable="closeable" :overflow-visible="overflowVisible" @close="close">
        <div>
            <div class="px-6 py-4">
                <div class="text-lg"><slot name="title" /></div>

                <div class="mt-4">
                    <slot name="content" />
                </div>
            </div>

            <div class="flex flex-row justify-end px-6 py-4 bg-gray-100 text-right">
                <slot name="footer" />
            </div>
        </div>
    </Modal>
</template>
